body,
html {
  background-color: transparent !important;
}

/*img {
  max-width: 100%;
  width: 100%;
}*/

/*
 *  Scroll Bar Plus
 */

.ocg-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(128, 5, 6, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(128, 5, 6, 0.8);
  border-radius: 10px;
  background-color: transparent;
}

.ocg-scrollbar::-webkit-scrollbar {
  width: 9px;
  background-color: transparent;
}

.ocg-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(128, 5, 6, 1);
}

.ocg-scrollbar-white::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(241, 241, 241, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(241, 241, 241, 0.8);
  border-radius: 10px;
  background-color: transparent;
}

.ocg-scrollbar-white::-webkit-scrollbar {
  width: 9px;
  background-color: transparent;
}

.ocg-scrollbar-white::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(241, 241, 241);
}

/*
* Modal Common Styles
*/

.modal-body {
  .modal-question {
    font-size: 31px;
  }

  .modal-info {
    .table {
      tbody {
        tr {
          th,
          td {
            font-size: 24px;
          }

          td {
            font-weight: 300;
          }
        }
      }
    }
  }
}

.modal-footer {
  .modal-question-btn {
    .btn {
      min-width: 130px;
      font-size: 24px;
    }
    .btn:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.table-fixed {
  width: 100%;
  tbody {
    overflow-y: auto;
    width: 100%;
  }
  thead,
  tbody,
  tr,
  td,
  th {
    display: block;
  }
  tbody {
    td {
      float: left;
      width: 20%;
    }
  }
  thead {
    tr {
      th {
        float: left;
        width: 20%;
      }
    }
  }
}
