/* Roboto regular */

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular.eot");
    src: url("../fonts/Roboto-Regular.eot?#iefix") format('embedded-opentype'),
         url("../fonts/Roboto-Regular.woff2") format('woff2'),
         url("../fonts/Roboto-Regular.woff") format('woff'),
         url("../fonts/Roboto-Regular.ttf") format('truetype');
  
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Light.eot");
    src: url("../fonts/Roboto-Light.eot?#iefix") format('embedded-opentype'),
         url("../fonts/Roboto-Light.woff2") format('woff2'),
         url("../fonts/Roboto-Light.woff") format('woff'),
         url("../fonts/Roboto-Light.ttf") format('truetype');
    font-weight: 300; 
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Thin.eot");
    src: url("../fonts/Roboto-Thin.eot?#iefix") format('embedded-opentype'),
         url("../fonts/Roboto-Thin.woff2") format('woff2'),
         url("../fonts/Roboto-Thin.woff") format('woff'),
         url("../fonts/Roboto-Thin.ttf") format('truetype');
    font-weight: 100; 
    font-style: normal;
}

